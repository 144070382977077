import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const Offers = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Oferte curente" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <p>Urmatoarele pachete fac parte din oferta noastră actuală</p>
          <hr />

          <h2 id="table-e6">Developare și scanare</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Provia 100F + Dev + Scan</td>
                <td>
                  36 poziții - DIAPOZITIV.
                  <Link to={`/developam-diapozitive`}> Detalii aici.</Link>
                </td>
                <td>85</td>
              </tr>
              <tr>
                <td>C-41 5 + 1 GRATIS</td>
                <td>
                  La 6 filme color 135 platesti doar 5.
                  <Link to={`/cinci-plus-unu-gratis`}> Detalii aici.</Link>
                </td>
                <td>125</td>
              </tr>
            </tbody>
          </table>

          <h2 id="table-c41">Oferte cu PRINT</h2>
          <table>
            <thead>
              <tr>
                <th>Produs</th>
                <th>Detalii</th>
                <th>Preț (RON)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Provia 100F + Dev + Scan + PRINT</td>
                <td>36 poziții - DIAPOZITIV</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Dev C-41 + scan + print format 10/15</td>
                <td>36 poziții</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Dev BW + scan + print format 10/15</td>
                <td>36 poziții</td>
                <td>45</td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => <Offers location={props.location} data={data} {...props} />}
  />
);
